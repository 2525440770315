import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import { useSiteMap } from '@unisporkal/sites';

import styles from './HeaderLogo.module.scss';
import GettyLogo from '../icons/GettyHeaderLogo.svg';

interface HeaderLogoProps {
  linkDisabled?: boolean;
  openNewTab?: boolean;
}

const HeaderLogo = ({ linkDisabled, openNewTab = false }: HeaderLogoProps) => {
  const t = useTranslations();
  const siteMap = useSiteMap();
  const href = siteMap.home();
  const navigationTracking = () => {
    const ga4Event = navigationEvent({
      link_text: 'Getty Images homepage',
      link_url: href,
      ui_element_location: 'header',
      navigation_selection: 'header-logo',
    });
    ga4Track(ga4Event);
  };

  return (
    <div
      className={styles.container}
      data-testid="nav-home"
    >
      {linkDisabled ? (
        <img
          src={GettyLogo}
          alt={t('getty_images_logo')}
          data-testid="GettyLogo"
        />
      ) : (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={href}
          data-nav="nav=head_Logo"
          data-testid="nav-header-logo"
          aria-label="Getty Images homepage"
          onClick={navigationTracking}
          target={openNewTab ? '_blank' : '_self'}
        >
          <img
            src={GettyLogo}
            alt={t('getty_images_logo')}
            data-testid="GettyLogo"
          />
        </a>
      )}
    </div>
  );
};

export default HeaderLogo;
