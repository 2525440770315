import React from 'react';
import { Flex, Box } from '@unisporkal/alliance-ui-flex-box';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import styles from './PrismHeader.module.scss';

const PrismHeader = () => (
  <header
    data-testid="prism-header"
    className={styles.container}
  >
    <Flex className={styles.innerContainer}>
      <Box className={styles.badge}>ALPHA PROGRAM</Box>
      <HeaderLogo openNewTab />
    </Flex>
  </header>
);

export default PrismHeader;
